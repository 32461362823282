// vendors
import React from 'react';
import { Link } from 'gatsby';

import SEO from '@src/components/SEO';
import ContentSection from '@src/views/AboutPageView/ContentSection/ContentSection';
import Layout from '../components/Layout';

// views
import HeroSection from '../views/AboutPageView/HeroSection';

const AProposPage = () => {
  const langLinks = [{ langKey: 'en', href: '/en/about' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Particuliers'
        description='Besoin de solutions de rangement domestique durables et de première qualité? Les produits Permafil sont robustes, polyvalents et s’adaptent à votre espace.'
      />

      <HeroSection>
        <h1>À propos de nous</h1>
      </HeroSection>

      <ContentSection>
        <h2>Histoire</h2>

        <p>
          Fondé en 1989 par un entrepreneur Beauceron, Permafil s’était donné au
          départ l’objectif de devenir un joueur important dans la fabrication
          de produits de rangement domestique.
        </p>

        <p>
          Trente années plus tard, seul fabricant de tablettes grillagées au
          Canada, Permafil a étendu ses activités à diverses catégories de
          produits et services et est un joueur dominant dans la fabrication et
          installation de tablettes grillagées, des cloisons et de rangements
          grillagés, des supports à vélos, des tablettes et mobilier de
          rangement en mélamine et des présentoirs commerciaux dans l’est du
          Canada.
        </p>

        <p>
          Après un changement d’actionnariat en 2009, toujours propriété
          d’entrepreneurs de chez nous, Permafil est principalement actif dans
          le monde la construction où il est reconnu comme un partenaire fiable
          fabriquant et installant des produits de qualité et assurant un
          service de premier choix grâce à une équipe professionnelle et dédiée
          à la satisfaction de la clientèle.
        </p>

        <p>
          Permafil collabore avec les plus grands joueurs de l’industrie de la
          construction au Québec et à l’est de l’Ontario dans la grande majorité
          des projets de construction d’immeubles multilogements de type condo,
          locatifs ou de résidences pour retraités. Permafil dessert également
          plusieurs joueurs majeurs du marché de la quincaillerie et vend
          également sur le web par le biais de sa boutique{' '}
          <a href='www.wallysystem.com'>www.wallysystem.com</a>.
        </p>

        <p>
          De plus la division des présentoirs commerciaux connait depuis
          plusieurs années un succès important auprès de grands manufacturiers
          qui de concert avec Permafil développent des solutions de mise en
          marché et d’étalage pour leurs produits partout en Amérique du nord.
        </p>

        <p>
          Bien décidé à maintenir la croissance soutenue des ventes des
          dernières années la direction de Permafil travaille sans cesse au
          développement de nouveaux produits et marchés qui assureront la
          pérennité et la croissance de l’entreprise.
        </p>

        <hr />

        <h2>Mission</h2>

        <p>
          Dans tous les secteurs d’activités où oeuvre Permafil, la poursuite
          d’une meilleure expérience client guide nos actions.
        </p>

        <hr />

        <h2>Nos valeurs</h2>

        <h3>Intégrité</h3>
        <p>
          Agir avec une honnêteté exemplaire envers nos clients, actionnaires,
          fournisseurs et employés afin de maintenir leur confiance au plus haut
          niveau.
        </p>

        <h3>Satisfaction totale des clients : un service exceptionnel</h3>
        <p>
          Offrir des solutions qui respectent et répondent aux attentes de nos
          clients externes et internes, par la communication, par notre capacité
          de réagir et par la livraison ponctuelle de produits de haute qualité
          et de services fiables.
        </p>

        <h3>
          Excellentes relations avec notre personnel et nos partenaires
          d’affaires
        </h3>
        <p>
          Favoriser un milieu de travail qui offre une communication franche, du
          perfectionnement, des possibilités d’avancement et des pratiques
          équitables où les réalisations de notre personnel sont reconnues.
          Maintenir un climat de confiance mutuelle avec nos partenaires
          d’affaires.
        </p>

        <h3>Produits de première qualité : aucun compromis</h3>
        <p>
          Livrer un produit conforme aux normes reconnues et aux attentes de nos
          clients par le biais de procédures d’amélioration continue sans
          compromis pour ce qui est des matériaux, de la conception et de la
          fabrication.
        </p>

        <h3>Fabrication à haute efficacité</h3>
        <p>
          Maintenir l’efficacité optimale de nos procédés en soutenant
          l’implantation des meilleures pratiques d’affaires dans notre usine de
          fabrication.
        </p>

        <h3>Milieu de travail sécuritaire, propre et ordonné</h3>
        <p>
          Développer l’engagement du personnel et prendre les mesures
          nécessaires pour créer un milieu de travail sécuritaire, sain et
          propre doté de l’équipement et de la technologie les plus récents.
        </p>

        <h3>Bon citoyen corporatif</h3>
        <p>
          Se conformer aux lois et aux règlements, protéger l’environnement et
          encourager l’entreprise et les employés à s’impliquer dans la
          collectivité.
        </p>

        <hr />

        <h2>
          <Link
            to='/protection-renseignements-prives'
            css={`
              color: inherit;
              text-underline-offset: 4px;

              :hover,
              :focus {
                text-decoration: none;
              }
            `}
          >
            Protection des renseignements privés
          </Link>
        </h2>
      </ContentSection>
    </Layout>
  );
};

export default AProposPage;
